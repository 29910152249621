// Material Components
import Hidden from "@mui/material/Hidden";
// Material Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useEffect } from "react";
import styled from "@emotion/styled";
import { useRouter } from "next/router";
import Link from "next/link";
import { theme } from "theme";
import { Favourite } from "common/PublicacionListItem/Favourite";
import {
  BottomPosts,
  Navigation,
  Tags,
  Contact,
  ButtonContent,
  PrepayWarning,
} from "./components";
import {
  Spacer,
  Gallery,
  Button,
  Loading,
  Breadcrumbs,
  useDispatch,
  useSelector,
  useCountry,
  DropdownFilter,
  ReadOnlyTextArea,
  useAnunciosMetadata,
} from "common";
import {
  selectMediaByIds,
  selectMediaIdsByPostId,
  selectPostBackLink,
  selectPost,
} from "selectors";
import { ESTADOS } from "consts";
import Head from "next/head";
import { push } from "connected-next-router";
import { incrementPostViewCountRequest, getPostRequest } from "requests";
import routes, { AnunciosQueryParams } from "routes";
import { TId } from "types";

type Props = {
  reviewPostId?: TId;
};

export const Anuncio = ({ reviewPostId }: Props) => {
  const dispatch = useDispatch();
  const country = useCountry();
  const router = useRouter();
  const {
    category: categoryKey,
    estado: estadoKey,
    localidad: localidadKey,
    postId: urlPostId,
    index,
  } = router.query as unknown as AnunciosQueryParams;
  const backLink = useSelector(selectPostBackLink);
  const postId = reviewPostId ?? urlPostId!;
  const post = useSelector((state) => selectPost(state, postId));
  const mediaIds = useSelector((state) =>
    selectMediaIdsByPostId(state, postId)
  );
  const media = useSelector((state) => selectMediaByIds(state, mediaIds));

  useEffect(() => {
    if (!postId) return;
    dispatch(
      getPostRequest(
        { postId },
        { onResponse: { errorCallback: () => dispatch(push(routes.home())) } }
      )
    );

    if (!reviewPostId) {
      dispatch(incrementPostViewCountRequest({ postId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  const location = estadoKey
    ? localidadKey
      ? ESTADOS[estadoKey].localidades[localidadKey].name
      : ESTADOS[estadoKey].name
    : country.name;

  const isReview = Boolean(reviewPostId) ? { approval: true } : undefined;

  const { title } = useAnunciosMetadata({ categoryKey, location });

  const followBackLink = () => {
    dispatch(push(backLink!.route));
  };

  return (
    <Container>
      <Head>{title}</Head>
      {!isReview && <DropdownFilter />}
      {backLink ? (
        <BackLink>
          <Button color="secondary" onClick={followBackLink}>
            <ButtonContent>
              <ArrowBackIcon />
              {backLink.text}
            </ButtonContent>
          </Button>
        </BackLink>
      ) : (
        <Spacer height={30} />
      )}
      <Navigation index={index} />
      <Content>
        <TopContainer>
          <Breadcrumbs />
          <Favourite post={post} />
        </TopContainer>
        {post ? (
          <>
            <Contact post={post} />
            <Title>{post?.title}</Title>
            <Spacer height={30} />
            <Tags post={post} />

            <Hidden mdUp>
              <Spacer height={10} />
              <Gallery media={media} isReview={isReview} />
            </Hidden>

            <PostTextContainer>
              <ReadOnlyTextArea value={post.text} />
            </PostTextContainer>

            <Hidden smDown>
              <Gallery media={media} isReview={isReview} />
            </Hidden>

            <Spacer height={10} />
            <Contact post={post} />
          </>
        ) : (
          <Loading />
        )}
        <Spacer height={30} />
        <PrepayWarning />
        <Report>
          <Link href={routes.contacto()} passHref>
            Reportar Anuncio
          </Link>
        </Report>
      </Content>
      <BottomPosts />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
`;
const PostTextContainer = styled.div`
  margin: 0 auto;
`;
const Content = styled.div`
  max-width: 935px;
  width: 100%;
  margin: 0 auto;
  border: 2px solid ${theme.palette.primary.dark};
  border-radius: 5px;
  padding: 10px;
  min-height: 900px;

  ${theme.breakpoints.up("sm")} {
    width: 90%;
  }
`;
const Title = styled.h1`
  color: ${theme.palette.primary.main};
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 5px;
  padding: 0 40px;
`;
const BackLink = styled.div`
  align-self: flex-start;
  width: 90%;
  max-width: 1000px;
  margin: 10px auto;

  button {
    overflow: hidden;
  }
`;
const Report = styled.div`
  text-align: center;
  text-decoration: underline;

  a {
    color: ${theme.palette.error.main};
  }
`;
const TopContainer = styled.div`
  display: flex;
`;
