import styled from "@emotion/styled";
import Link from "next/link";
import routes from "routes";
import { theme } from "theme";

export const PrepayWarning = () => {
  return (
    <Container>
      <p>
        LOCALEROTICA <strong>NO</strong> es intermediario entre las relaciones
        de los usuarios y <strong>NO</strong> contacta para solicitar dinero o
        datos personales.
      </p>
      <Link prefetch={false} href={routes.recomendaciones()}>
        Recomendaciones de seguridad
      </Link>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  color: ${theme.palette.warning.main};

  p {
    margin-bottom: 5px;
  }

  a {
    color: ${theme.palette.warning.main};
    text-decoration: underline;
    margin-bottom: 40px;
  }
`;
