// Material Icons
import CallIcon from "@mui/icons-material/Call";

import styled from "@emotion/styled";
import { useDispatch, useCountry } from "common";
import { TPost } from "types";
import { mixpanelPostContact } from "mixpanelEventActions";
import { whatsappImageSrc } from "res";
import { theme } from "theme";

export const Contact = ({ post }: { post: TPost }) => {
  const dispatch = useDispatch();
  const country = useCountry();

  const message = `Hola! Vi tu anuncio "${post.title}" en LOCALEROTICA.`;

  const trackPostContact = (method: "whatsapp" | "phonecall") => () => {
    dispatch(mixpanelPostContact({ phoneNumber: post.phoneNumber, method }));
  };

  return (
    <Container>
      {post.showWhatsapp && (
        <WhatsApp onClick={trackPostContact("whatsapp")}>
          <a
            rel="noreferrer"
            href={whatsappHrefBuilder({
              message,
              phoneNumber: post.phoneNumber,
              phoneCountryCode: country.phoneCode ?? "52",
            })}
            target="_blank"
          >
            <img src={whatsappImageSrc} alt="whatsapp" />
            WhatsApp
          </a>
        </WhatsApp>
      )}
      {post.showPhoneNumber && (
        <PhoneNumber
          phoneNumberPresent={Boolean(post.phoneNumber)}
          onClick={trackPostContact("phonecall")}
        >
          <a href={phoneCallHrefBuilder({ phoneNumber: post.phoneNumber })}>
            <CallIcon />
            {/* We add 0's so avoid layout shift when going from no number loaded
            to number loaded */}
            {post.phoneNumber || "0".repeat(10)}
          </a>
        </PhoneNumber>
      )}
    </Container>
  );
};

const phoneCallHrefBuilder = ({ phoneNumber }: { phoneNumber: string }) => {
  return `tel:${phoneNumber}`;
};

export const whatsappHrefBuilder = ({
  message,
  phoneNumber,
  phoneCountryCode,
}: {
  message: string;
  phoneNumber: string;
  phoneCountryCode: string;
}) => {
  return `https://wa.me/${phoneCountryCode}${phoneNumber}/?text=${encodeURI(
    message,
  )}`;
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;
const ContactMethod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  a {
    display: flex;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  }

  svg {
    margin-right: 5px;
  }

  img {
    height: 30px;
    width: 30px;
    margin-right: 5px;
  }
`;
const PhoneNumber = styled(ContactMethod)<{ phoneNumberPresent: boolean }>`
  a {
    color: white;
    text-decoration: underline;
    ${({ phoneNumberPresent }) =>
      phoneNumberPresent
        ? "color: white;"
        : `color: ${theme.palette.background.default};`}
    text-decoration-color: white;
  }

  svg {
    color: white !important;
  }
`;
const WhatsApp = styled(ContactMethod)`
  a {
    color: lightgreen;
    text-decoration: underline;
  }
`;
