// Material icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
// lodash
import isEmpty from "lodash/isEmpty";

import styled from "@emotion/styled";
import { useSelector, Button } from "common";
import { selectPost, selectCurrentQueryResults } from "selectors";
import Link from "next/link";
import routes from "routes";

type Props = {
  index?: string;
};

export const Navigation = ({ index }: Props) => {
  const currentQueryResults = useSelector(selectCurrentQueryResults);

  const nextIndex = Number(index) + 1;
  const prevIndex = Number(index) - 1;

  const nextPost = useSelector((state) =>
    selectPost(state, currentQueryResults[nextIndex])
  );

  const prevPost = useSelector((state) =>
    selectPost(state, currentQueryResults[prevIndex])
  );

  if (!index || isEmpty(currentQueryResults)) return null;

  return (
    <NavButtonsContainer>
      {prevPost ? (
        <Link
          href={routes.anuncios({
            category: prevPost.category,
            estado: prevPost.estado,
            localidad: prevPost.localidad,
            postId: prevPost.id,
            index: String(prevIndex),
          })}
          passHref
        >
          <div>
            <Button color="secondary">
              <ButtonContent>
                <ArrowBackIcon />
                Anterior
              </ButtonContent>
            </Button>
          </div>
        </Link>
      ) : (
        <div />
      )}
      {nextPost && (
        <Link
          href={routes.anuncios({
            category: nextPost.category,
            estado: nextPost.estado,
            localidad: nextPost.localidad,
            postId: nextPost.id,
            index: String(nextIndex),
          })}
          passHref
        >
          <div>
            <Button color="secondary">
              <ButtonContent>
                Siguiente
                <ArrowForwardIcon />
              </ButtonContent>
            </Button>
          </div>
        </Link>
      )}
    </NavButtonsContainer>
  );
};

const NavButtonsContainer = styled.div`
  position: -webkit-sticky; /* Safari */
  position: sticky;
  padding: 0 7%;
  z-index: 1;
  margin-top: 10px;
  margin-bottom: 20px;
  top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
`;
export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  width: 100px;
`;
