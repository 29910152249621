// lodash
import isEmpty from "lodash/isEmpty";
import times from "lodash/times";

import styled from "@emotion/styled";
import { useRouter } from "next/router";
import { useCountry, useSelector } from "common";
import { selectCurrentQueryResults } from "selectors";
import { Post } from "./Post";
import { AnunciosQueryParams } from "routes";
import { CATEGORIES, ESTADOS } from "consts";

export const BottomPosts = () => {
  const router = useRouter();
  const country = useCountry();
  const currentQueryResults = useSelector(selectCurrentQueryResults);
  const {
    index,
    category: categoryKey,
    estado: estadoKey,
    localidad: localidadKey,
  } = router.query as unknown as AnunciosQueryParams;

  if (!index || isEmpty(currentQueryResults)) return null;

  const category = CATEGORIES[categoryKey];
  const location = estadoKey
    ? localidadKey
      ? ESTADOS[estadoKey].localidades[localidadKey].name
      : ESTADOS[estadoKey].name
    : country.name;

  return (
    <Container>
      <h2>
        Más {category} en {location}
      </h2>
      <Posts>
        {times(3, (idx) => {
          const newIndex = Number(index) + idx + 1;
          const nextPostId = currentQueryResults[newIndex];
          if (!nextPostId) return null;
          return (
            <Post
              postId={nextPostId}
              key={`${nextPostId}-${newIndex}`}
              newIndex={newIndex}
            />
          );
        })}
      </Posts>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 50px;

  h2 {
    margin: 20px;
  }
`;
const Posts = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
