// Material Components
import Grid from "@mui/material/Grid";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";

import styled from "@emotion/styled";
import { usePostTags } from "common";
import { verifiedImageSrc } from "res";
import { TPost } from "types";

export const Tags = ({ post }: { post: TPost }) => {
  const tags = usePostTags({ postId: post.id });

  return (
    <TagsContainer>
      {tags.verified && (
        <div>
          <Tag src={verifiedImageSrc} alt="fotos verificadas" />
          <p>Fotos verificadas</p>
        </div>
      )}
      {tags.noAdvancePayment && (
        <div>
          <IconContainer>
            <MoneyOffIcon />
          </IconContainer>
          <p>Sin anticipo</p>
        </div>
      )}
    </TagsContainer>
  );
};

const TagsContainer = styled(Grid)`
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;

  p {
    margin: 0 0 0 5px;
  }

  > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
  }
`;
const Tag = styled.img`
  width: 30px;
  height: 30px;
`;
const IconContainer = styled.div`
  svg {
    color: white;
    height: 32px;
    width: auto;
  }
`;
