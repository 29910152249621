// lodash
import filter from "lodash/filter";
import first from "lodash/first";

import styled from "@emotion/styled";
import Link from "next/link";
import { Image, useSelector } from "common";
import {
  selectMediaByIds,
  selectPost,
  selectMediaIdsByPostId,
} from "selectors";
import { TId } from "types";
import { theme } from "theme";
import routes from "routes";

type Props = {
  postId: TId;
  newIndex: number;
};

export const Post = ({ postId, newIndex }: Props) => {
  const post = useSelector((state) => selectPost(state, postId));
  const mediaIds = useSelector((state) =>
    selectMediaIdsByPostId(state, postId)
  );
  const images = useSelector((state) =>
    filter(selectMediaByIds(state, mediaIds), (media) => media.type === "image")
  );
  const thumbnail = first(images);

  if (!post) return null;

  return (
    <Container>
      <Link
        href={routes.anuncios({
          category: post.category,
          estado: post.estado,
          localidad: post.localidad,
          postId: post.id,
          index: String(newIndex),
        })}
        passHref
      >
        <Content>
          <Image media={thumbnail!} size="thumbnail" alt="escort thumbnail" />
          <h3>{post.title}</h3>
        </Content>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  margin: 20px 30px;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: ${theme.palette.primary.main};
  text-decoration: underline;
  min-width: 200px;

  > * {
    cursor: pointer;
  }

  img {
    object-fit: cover;
    min-width: 134px;
    max-width: 134px;
    height: 200px;
    border-radius: 4px;
  }
`;
